export const educationData = [
    {
        id: 3,
        institution: 'Queensland University of Technology',
        course: 'Bachelors of Information Technology (Computer Science)',
        startYear: '2021',
        endYear: 'Present'
    },
    {
        id: 2,
        institution: 'TAFE Queensland',
        course: 'Certificate II in Engineering Split Systems',
        startYear: '2015'
    },
    {
        id: 1,
        institution: 'TAFE Queensland',
        course: 'Certificate III in Electrotechnology Electrician',
        startYear: '2009',
        endYear: '2013'
    },
]