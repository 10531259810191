import resume from '../assets/pdf/resume.pdf'
import profilePic from '../assets/profile.jpg'

export const headerData = {
    name: 'Jacob Hollister',
    title: "Software Developer",
    desciption:"I'm a software developer with a passion for building and designing digital experiences. Currently, I'm studying computer science at Queensland University of Technology.",
    image: profilePic,
    resumePdf: resume
}
