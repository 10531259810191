import award3 from "../assets/png/award_3.pdf.png"
import award2 from "../assets/png/award_2.pdf.png"
import award1 from "../assets/png/award_1.pdf.png"
import award1pdf from '../assets/pdf/award_1.pdf'
import award2pdf from '../assets/pdf/award_2.pdf'
import award3pdf from '../assets/pdf/award_3.pdf'

export const achievementData = {
    bio : "The illiterate of the 21st century will not be those who cannot read and write, but those who cannot learn, unlearn, and relearn. - Alvin Toffler",
    achievements : [
        {
            id : 1,
            title : "Dean's List for Excellent Academic Performance Semester 1, 2021",
            details : "The Dean's List for Excellent Academic Performance acknowledges students who have achieved a semester Grade Point Average (GPA) of 6.50, or above.",
            date : 'Oct 11, 2021',
            field : 'Academic',
            image : award1,
            pdf: award1pdf
        },
        {
            id : 2,
            title : "Dean's List for Excellent Academic Performance Semester 2, 2021",
            details : "The Dean's List for Excellent Academic Performance acknowledges students who have achieved a semester Grade Point Average (GPA) of 6.50, or above.",
            date : 'May 5, 2022',
            field : 'Academic',
            image : award2,
            pdf: award2pdf
        },
        {
            id : 3,
            title : ' Dean’s List for Excellent Academic Performance Semester 1, 2022',
            details : "The Dean's List for Excellent Academic Performance acknowledges students who have achieved a semester Grade Point Average (GPA) of 6.50, or above.",
            date : 'Oct 6, 2022',
            field : 'Academic',
            image : award3,
            pdf: award3pdf
        }
    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/