import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'

import stocks from "../assets/png/stocks.png"
import MazeCraft from "../assets/png/mazeCraft.png"
import volcanoes from "../assets/png/volcanoes.png"
import crimes from "../assets/png/crimes.png"


export const projectsData = [
    {
        id: 1,
        projectName: 'Stock Tracker',
        projectDesc: 'A full-stack web application developed using MongoDB, Express, React and Node (MERN Stack), to view and manage stock and cryptocurrency trading',
        tags: ['React', 'NodeJS' ,'MongoDB', 'Express'],
        code: 'https://github.com/JacobHollister/stock_tracker_full_stack',
        demo: 'https://tense-pantsuit-elk.cyclic.app/',
        image: stocks
    },
    {
        id: 2,
        projectName: 'Brisbane Crime Suburbs',
        projectDesc: 'A web application built using Express, React and NodeJS, which allows users to view and compare crime statistics of Queensland suburbs',
        tags: ['React', 'NodeJS' , 'Express', "LeafletJS"],
        code: 'https://github.com/JacobHollister/CAB432_mashup',
        demo: 'https://dull-coat-fawn.cyclic.app/',
        image: crimes
    },
    {
        id: 3,
        projectName: 'MazeCraft',
        projectDesc: 'A full functional maze generation application developed in Java that allows users to create customizable mazes, generate solvable mazes, dynamically display solutions for mazes, load and save mazes',
        tags: ['Java'],
        code: 'https://github.com/JacobHollister/MazeCraft',
        image: MazeCraft
    },
    {
        id: 4,
        projectName: 'Volcanoes',
        projectDesc: 'A full-stack web application developed using MongoDB, Express, React and Node (MERN Stack), that displays information on volcanoes',
        tags: ['React', 'NodeJS' ,'MongoDB', 'Express', "Framer"],
        code: 'https://github.com/JacobHollister/volcanoes_app',
        demo: 'https://zany-pink-scarab.cyclic.app/',
        image: volcanoes
    }
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/